import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';

import {
  TestimonialSlideWrapper,
  TestimonialItem,
  TestimonialMeta,
  AuthorInfo,
  AuthorImage,
} from './testimonial.style';
import { Divider, Grid, List, ListItem } from '@mui/material';

const TestimonialSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secIntroductionText,
  secHeading,
  reviewTitle,
  review,
  name,
  designation,
  clinicIntroductionBox,
  titleBox,
}) => {
  const carouselOptions = {
    type: 'carousel',
    autoplay: 6000,
    perView: 2,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 1,
      },
    },
  };

  return (
    <Box {...sectionWrapper} as='section' id='testimonial_section'>
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content='VOICE' />
          <Heading {...secHeading} content='導入事例' />
        </Box>
        <Grid container {...titleBox}>
          <Grid item xs={12}>
            <Heading as='h3' content='クリニックの残業時間が1/10に' />
          </Grid>
          <Text {...secIntroductionText} content='画像' />
        </Grid>
        <Grid container {...clinicIntroductionBox}>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Heading as='h3' content='上田歯科クリニック' />
            <Text
              {...secIntroductionText}
              content='上田歯科クリニックの紹介文'
            />
          </Grid>
        </Grid>
        <List>
          <ListItem>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Heading as='h2' content='目的' {...reviewTitle} />
              </Grid>
              <Grid item xs={8}>
                <Text as='h5' content='課題' />
                <Text as='h5' content='課題' />
                <Text as='h5' content='課題' />
              </Grid>
            </Grid>
          </ListItem>
          <Divider variant='middle'></Divider>
          <ListItem>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Heading as='h2' content='課題' {...reviewTitle} />
              </Grid>
              <Grid item xs={8}>
                <Text as='h5' content='課題' />
                <Text as='h5' content='課題' />
                <Text as='h5' content='課題' />
              </Grid>
            </Grid>
          </ListItem>
          <Divider variant='middle'></Divider>
          <ListItem>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Heading as='h2' content='効果' {...reviewTitle} />
              </Grid>
              <Grid item xs={8}>
                <Text as='h5' content='課題' />
                <Text as='h5' content='課題' />
                <Text as='h5' content='課題' />
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
};

TestimonialSection.defaultProps = {
  titleBox: {
    pb: ['60px', '80px', '90px', '100px', '100px'],
    textAlign: 'center',
  },
  clinicIntroductionBox: {
    pb: ['60px', '80px', '90px', '100px', '100px'],
  },
  sectionWrapper: {
    pt: ['100px', '100px', '100px', '140px', '160px'],
    pb: ['60px', '80px', '90px', '100px', '100px'],
  },
  secTitleWrapper: {
    mb: ['40px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '5px',
  },
  secIntroductionText: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'primary',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  reviewTitle: {
    fontWeight: '500',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '13px',
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0,
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px',
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0,
  },
};

export default TestimonialSection;

// export const query = graphql`
//   query {
//     allMicrocmsPitto_voice {
//       edges {
//         node {
//           title
//         }
//       }
//     }
//   }
// `;
// export const query = graphql`
//   query {
//     allMicrocmsNews {
//       edges {
//         node {
//           date(formatString: "MMMM DD, YYYY")
//           title
//         }
//       }
//     }
//   }
// `;
